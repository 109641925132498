import React from "react"
import { useStaticQuery, graphql } from 'gatsby';
import { FaqDetail } from "../../../components/faq/detail"

const breadcrumbsData: { text: string; path: string }[] = [
  { text: `FAQ`, path: `question` },
  { text: `採用`, path: `question/recruit` },
]

const faqNavItems: { text: string; path: string }[] = [
  { text: `会社について`, path: `#faq_company` },
  { text: `応募・選考について`, path: `#faq_selection` },
  { text: `入社後について`, path: `#faq_joined` },
  { text: `福利厚生 / 会社の制度について`, path: `#faq_benefits` },
  { text: `その他`, path: `#faq_other` },
]

const FaqTitle: string = "採用に関するFAQ";

export default function FaqRecruitPage() {
  const { site: { siteMetadata: { faqDetail } } } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          faqDetail {
            recruit {
              details {
                answer
                question
              }
              path
            }
          }
        }
      }
    }
  `);

  const [ faqDetails ] = faqDetail;

  return (
    <FaqDetail
      breadcrumbsData={breadcrumbsData}
      faqNavItems={faqNavItems}
      faqDetails={faqDetails.recruit}
      faqTitle={FaqTitle}
    />
  )
}